<template>
    <div class="register">
        <div class="register-wrap">
            <div class="register-card">
                <el-card shadow="always">
                    <h2 class="mb-30" align="center">{{ $t('data.register.registrationSystem') }}</h2>

                    <el-form ref="form" :model="form" label-width="200px">
                        <div class="register-form">
                            <h3 class="mb-20">{{ $t('data.register.organizationInformation') }}:</h3>

                            <el-form-item :label="$t('data.register.organizationName')">
                                <el-input v-model="form.name"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.organizationAddress')">
                                <el-input v-model="form.address"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.country')">
                                <el-select v-model="form.country_id" :placeholder="$t('data.public.select')" style="width:100%">
                                    <el-option v-for="(item, index) in countryData" :key="index" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.contactNumber')">
                                <div style="display: flex;">
                                    <el-select v-model="form.country_id" :placeholder="$t('data.public.select')" style="width: 400px;">
                                        <el-option v-for="(item, index) in countryData" :key="index" :label="item.area_code" :value="item.id"></el-option>
                                    </el-select>
                                    <el-input v-model="form.contact_number" style="margin-left: 15px;"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.email')">
                                <el-input v-model="form.email"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.website')">
                                <el-input v-model="form.website"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.organisationType')">
                                <el-select v-model="form.type_id" :placeholder="$t('data.public.select')" style="width:100%">
                                    <el-option v-for="(item, index) in orgType" :key="index" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.oneContactPerson')">
                                <el-input v-model="form.one_contact_person"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.twoContactPerson')">
                                <el-input v-model="form.two_contact_person"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.organizationLogo')">
                                <image-upload :btnText="'Upload Logo'" @imageFile="imageFile"></image-upload>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.campusPicture')">
                                <image-upload :btnText="'Upload Picture 1'" @imageFile="imageFile"></image-upload>
                                <image-upload :btnText="'Upload Picture 2'" @imageFile="imageFile"></image-upload>
                                <image-upload :btnText="'Upload Picture 3'" @imageFile="imageFile"></image-upload>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.registrations')">
                                <file-upload v-for="(item, index) in form.documents" :key="index" :btnText="{index: index, text:'Upload File'}" @imageFile="imageFile"></file-upload>
                                <div class="register-add-btn">
                                    <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" round @click="addFile()">{{ $t('data.register.addFile') }}</el-button>
                                </div>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.about')">
                                <el-input type="textarea" v-model="form.about" rows="5"></el-input>
                            </el-form-item>
                        </div>

                        <div class="register-form">
                            <h3 class="mb-10">{{ $t('data.register.branchInformation') }}:</h3>

                            <div v-for="(item, index) in form.branches" :key="index">
                                <h4 class="mb-20">{{ $t('data.register.branch') }} {{++index}}:</h4>
                                <el-form-item :label="$t('data.register.branchName')">
                                    <el-input v-model="item.name"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('data.register.branchAddress')">
                                    <el-input v-model="item.address"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('data.register.country')">
                                    <el-select v-model="item.country_id" :placeholder="$t('data.public.select')" style="width:100%">
                                        <el-option v-for="(item, index) in countryData" :key="index" :label="item.name" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('data.register.contactNumber')">
                                    <div style="display: flex;">
                                        <el-select v-model="item.country_id" :placeholder="$t('data.public.select')" style="width: 400px">
                                            <el-option v-for="(item, index) in countryData" :key="index" :label="item.area_code" :value="item.id"></el-option>
                                        </el-select>
                                        <el-input v-model="item.contact_number" style="margin-left: 15px;"></el-input>
                                    </div>
                                    
                                </el-form-item>
                                <el-form-item :label="$t('data.register.email')">
                                    <el-input v-model="item.email"></el-input>
                                </el-form-item>
                            </div>

                            <div class="register-add-btn">
                                <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" round @click="addBranch()">{{ $t('data.register.addBranch') }}</el-button>
                            </div>

                        </div>

                        <div class="form-agreement">
                            <div v-html="agreement" style="font-size: 12px;"></div>
                            <div class="form-agreement-checkbox">
                                <el-checkbox v-model="form.is_agree">{{ $t('data.public.agreeDisclaimer') }}</el-checkbox>
                            </div>
                        </div>

                        <div style="text-align: center;">
                            <el-button type="primary" class="mt-30" @click="formSubmit" v-html="$t('data.register.submit')"></el-button>
                        </div>
                    </el-form>

                </el-card>
            </div>

            <div class="register-language">
                <el-select v-model="language" :placeholder="$t('data.public.select')" size="mini" @change="languageChange">
                    <el-option label="简体" value="cn"></el-option>
                    <el-option label="繁體" value="hk"></el-option>
                    <el-option label="EN" value="en"></el-option>
                </el-select>
            </div>
        </div>
    </div>
</template>
<script>
import ImageUpload from '../../components/imageUpload/ImageUpload'
import FileUpload from '../../components/fileUpload/FileUpload'
import { getCounties,organizationType,userRegister,getProtocols } from '../../api/index'
import { ElMessage } from 'element-plus'
export default {
    name: 'register',
    components: {
        ImageUpload,
        FileUpload
    },
    data(){
        return {
            orgType:[],
            form:{
                name: '',
                address: '',
                type_id: null,
                country_id: null,
                area_code: '',
                contact_number: '',
                email: '',
                website: '',
                one_contact_person: '',
                two_contact_person: '',
                logo: '',
                campus_pictures: ['','',''],
                documents: [''],
                certification: '',
                about: '',
                branches: [{
                    name: '',
                    address: '',
                    country_id: '',
                    area_code: '',
                    contact_number: '',
                    email: ''
                }],
                is_agree: false 
            },
            countryData: {},
            language: localStorage.getItem("language") || 'en',
            agreement: ''
        }
    },
    mounted(){
        this.getCountryData();
    },
    methods: {
        getCountryData(){
            getCounties({}).then( res => {
                this.countryData = res.data;
            })
            organizationType({}).then( res => {
                this.orgType = res.data;
            })
            let apiData = {
                protocol: 'register'
            }
            getProtocols({apiData}).then( res => {
                this.agreement = res.data.content.replace(/\r\n/g,"<br/>");
            })
        },
        imageFile(imageFile){
            if(imageFile.text === 'Upload Logo'){
                this.form.logo = imageFile.url;
                return;
            } else if(imageFile.text === 'Upload Picture 1'){
                this.form.campus_pictures[0] = imageFile.url;
                return;
            } else if(imageFile.text === 'Upload Picture 2'){
                this.form.campus_pictures[1] = imageFile.url;
                return;
            } else if(imageFile.text === 'Upload Picture 3'){
                this.form.campus_pictures[2] = imageFile.url;
                return;
            } else if(imageFile.text.text === 'Upload File'){
                this.form.documents[imageFile.text.index] = imageFile.url;
                return;
            }
        },
        addFile(){
            this.form.documents.push('');
        },
        addBranch(){
            const obj = {
                name: '',
                address: '',
                country_id: '',
                area_code: '',
                contact_number: '',
                email: ''
            }
            this.form.branches.push(obj);
        },
        formSubmit(){
            if(this.form.is_agree){
                if(this.form.country_id){
                    this.form.area_code = this.countryData.filter(item => item.id === this.form.country_id)[0].area_code;
                    this.form.branches.forEach((row, index) => {
                        if(this.form.branches[index].country_id){
                            this.form.branches[index].area_code = this.countryData.filter(item => item.id === this.form.branches[index].country_id)[0].area_code;
                        }
                    });
                }
                let apiData = this.form;
                userRegister({apiData}).then( res => {
                    ElMessage.success({
                        message: 'Success',
                        type: 'success'
                    });
                    this.$router.push('/login');
                })

            }else{
                ElMessage.error({
                    message: '請同意協議後再提交',
                    type: 'error'
                });
            }
        },
        languageChange(val){
            localStorage.setItem("language", val);
            this.$router.go(0);
        }
    }
}
</script>
<style scoped>

.register{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
}
.register-wrap {
    position: relative;
    width: 100%;
    min-width: 1200px;
    background-color: #224486;
    background-size: 100%;
}
.register-card{
    margin: 0 auto;
    width: 800px;
    padding: 50px 30px;
    overflow: auto;
}

.register-card .el-card {
    background-color: #e4efff;
}
.register-form{
    margin-bottom: 30px;
}
.register-form .image-upload{
    margin: 0px 0px;
}

.form-agreement{
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    background-color: #FFFFFF;
    padding: 20px;
    height: 140px;
    width: 500px;
    margin: 0 auto;
    overflow-y: auto;
}
.form-agreement p{
    line-height: 26px;
}
.form-agreement-checkbox{
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 20px;
}
.register-add-btn{
    display: block;
    text-align: center;
}
.register-language{
    position:absolute;
    top: 5px;
    right: 5px;
}
</style>
            